.chart-bar {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart-bar__inner {
  height: 100%;
  width: 100%;
  border: 1px solid #2a9d8f;
  border-radius: 12px;
  background-color: #ccc;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.chart-bar__fill {
  background-color: #264653;
  width: 100%;
  transition: all 0.3s ease-out;
}

.chart-bar__label {
  color: #264653;
  font-weight: bold;
  font-size: 0.5rem;
  text-align: center;
}
