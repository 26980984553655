.expense-date {
  display: flex;
  flex-direction: column;
  width: 5.5rem;
  height: 5.5rem;
  border: 1px solid #ececec;
  background-color: #264653;
  color: white;
  border-radius: 12px;
  align-items: center;
  justify-content: center;

  &__month {
    font-size: 0.75rem;
    font-weight: bold;
  }

  &__year {
    font-size: 0.75rem;
  }

  &__day {
    font-size: 1.5rem;
    font-weight: bold;
  }
}
