.new-expense {
  background-color: #2a9d8f;
  padding: 1rem;
  margin: 2rem auto;
  width: 50rem;
  max-width: 95%;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}

.new-expense button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 1px solid #264653;
  background-color: #264653;
  color: #e9c46a;
  border-radius: 12px;
  margin-right: 1rem;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.new-expense button:hover,
.new-expense button:active {
  color: #264653;
  background-color: #e9c46a;
  border-color: #e9c46a;
}

.new-expense button.alternative {
  color: #b88c9e;
  border-color: transparent;
  background-color: transparent;
}

.new-expense button.alternative:hover,
.new-expense button.alternative:active {
  background-color: #704c5e;
}
