.expense-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  margin: 1rem 0;
  background-color: #2a9d8f;

  &__description {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
    flex-flow: column-reverse;
    justify-content: flex-start;
    flex: 1;

    @media (min-width: 580px) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }

  h2 {
    font-size: 1rem;
    flex: 1;
    margin: 0 1rem;
    color: white;

    @media (min-width: 580px) {
      font-size: 1.25rem;
    }
  }

  &__price {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    color: white;
    background-color: #264653;
    border: 1px solid white;
    padding: 0.5rem;
    border-radius: 12px;

    @media (min-width: 580px) {
      font-size: 1.25rem;
      padding: 0.5rem 1.5rem;
    }
  }
}
